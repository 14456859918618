<template>
  <div class="setting">
    <van-cell title="服务协议" is-link @click="openpage('agreement')" />
    <van-cell title="隐私政策" is-link @click="openpage('policy')" />
    <!---->
    <div class="end-btn-box">
      <van-button round block type="warning" @click="logout()">
        <span>退出登录</span>
      </van-button>
    </div>
    <!---->

    <!--服务协议-->
    <van-popup
      v-model="showPopup"
      closeable
      class="iframe-popup"
      :style="{ width: '90%', height: '90%' }"
    >
      <StaticPage :invoke="invoke" />
    </van-popup>
  </div>
</template>

<script>
import StaticPage from "@/components/StaticPage.vue";
export default {
  name: "Setting",
  components: {
    StaticPage,
  },
  data() {
    return {
      showPopup: false,
      invoke: null,
    };
  },
  created() {},
  methods: {
    openpage(page) {
      this.invoke = page;
      this.showPopup = true;
    },
    logout() {
      this.$dialog
        .confirm({
          message: "确定要退出吗？",
        })
        .then(() => {
          this.$toast.loading({
            forbidClick: true,
            duration: 0,
          });
          this.$ajax
            .post("/account/session/logout/", {}, "loading")
            .then(() => {
              this.clearAndClose();
            });
        })
        .catch(() => {
          this.clearAndClose();
        });
    },
    clearAndClose() {
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            WeixinJSBridge.call("closeWindow");
          },
          false
        );
        //微信浏览器ios关闭窗口
        window.WeixinJSBridge.call("closeWindow");
        parent.WeixinJSBridge.call("closeWindow");
      }, 800);
    },
  },
};
</script>
